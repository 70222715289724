<template>
  <div class="history-data">
    <a-space>
      <a-select style="width: 160px" @change="onChange" v-model="dataId">
        <a-select-option v-for="item in paraList" :value="item.dataId">{{ item.name }}</a-select-option>
      </a-select>

      <range-date-picker
          ref="rangeDate"
          @change="onDateChange" style="width: 100%"></range-date-picker>

      <a-button icon="search" type="primary" @click="clickSearch">搜索</a-button>

<!--      <a-button icon="upload" @click="clickExport">导出</a-button>-->

    </a-space>

    <div id="chart-view" class="chart-view"></div>

  </div>
</template>

<script>
import YongleParaUtil from "../paras/YongleParaUtil"
import * as echarts from 'echarts';
export default {
  props: ["deviceId","paraList"],
  data(){
    return {
      myChart:null,
      list:[],
      dataId:"",
      timeFrom:0,
      timeTo:0
    }
  },
  computed:{
    currentPara(){
      for(let i=0;i<this.paraList.length;i++){
        if(this.paraList[i].dataId == this.dataId){
          return this.paraList[i]
        }
      }
    },
  },
  mounted(){
    this.myChart = echarts.init(document.getElementById("chart-view"))
    this.onChange(this.paraList[0].dataId)
    this.getHistoryData()
  },

  methods:{
    clickSearch(){
      this.getHistoryData()
    },
    clickExport(){

    },
    onChange(){
      this.getHistoryData()
    },
    onDateChange (timeFrom, timeTo) {
      this.timeFrom = timeFrom
      this.timeTo = timeTo
    },
    getHistoryData(){
      let data = {
        dataId:this.currentPara.dataId,
        timeFrom:this.timeFrom,
        timeTo:this.timeTo
      }
      this.$get(`web/iot/device/history/${this.deviceId}`,data).then(suc=>{
        this.list = suc.data
        this.updateChart()
      })
    },

    updateChart(){
      let data = [];
      this.list.forEach(item=>{
        data.push([new Date(item.time),item.dataValue])
      })

      let option = {
        tooltip: {
          trigger: 'axis',
          position: function (pt) {
            return [pt[0], '10%'];
          }
        },
        title: {
          left: 'center',
          text: ''
        },
        xAxis: {
          type: 'time',
          boundaryGap: false
        },
        yAxis: {
          type: 'value',
          boundaryGap: [0, '100%']
        },
        dataZoom: [
          {
            type: 'inside',
            start: 0,
            end: 100
          },
          {
            start: 0,
            end: 100
          }
        ],
        series: [
          {
            name: this.currentPara.name,
            type: 'line',
            smooth: true,
            symbol: 'none',
            areaStyle: {},
            data: data
          }
        ]
      }

      this.myChart.setOption(option);
    }
  }
}
</script>

<style lang="less" scoped>
.history-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .chart-view{
    margin-top: 20px;
    width: 1000px;
    height: 400px;
  }

}
</style>
