let self = {
    paraList:[
        {
            name:"水温",
            dataId:"10",
            unit:"℃",
            rate:10
        },
        {
            name:"酸碱",
            dataId:"19",
            unit:"PH",
            rate:100
        },
        {
            name:"ORP",
            dataId:"16",
            unit:"MV"
        },
        {
            name:"余氯",
            dataId:"13",
            unit:"PPM",
            rate:100
        },

    ],
    switchList:[

    ],

    purification:{
        timing:[
            {
                icon:"",
                name:"酸碱设定",
                set:{
                    dataId:"110",
                    divider:0.1
                },
                start:{
                    name:"酸碱加药时间",
                    dataId:"111",
                    statusDataId:"62",
                },
                stop:{
                    name:"酸碱加药停止时间",
                    dataId:"112",
                    statusDataId:"63",
                }
            },
            {
                icon:"",
                name:"ORP设定",
                set:{
                    dataId:"113",
                    divider:1
                },
                start:{
                    name:"ORP加药时间",
                    dataId:"114",
                    statusDataId:"64",
                },
                stop:{
                    name:"ORP加药停止时间",
                    dataId:"115",
                    statusDataId:"65",
                }
            },
            {
                icon:"",
                name:"余氯设定",
                set:{
                    dataId:"116",
                    divider:0.1
                },
                start:{
                    name:"余氯加药时间",
                    dataId:"117",
                    statusDataId:"66",
                },
                stop:{
                    name:"余氯加药停止时间",
                    dataId:"118",
                    statusDataId:"67",
                }
            }

        ],
        control:[
            {
                icon: require("@/assets/btn_medical.png"),
                selectedIcon: require("@/assets/btn_medical_selected.png"),
                name: "PH泵",
                writeDataId:"89",
                readDataId:"48"
            },
            {
                icon: require("@/assets/btn_medical.png"),
                selectedIcon: require("@/assets/btn_medical_selected.png"),
                name: "氯泵",
                writeDataId:"90",
                readDataId:"49"
            },

        ],
        mode:[
            {
                name:"药泵控制模式",
                dataId:"100",
                action1:"手动",
                action2:"间断"
            },
            {
                name:"PH泵加药模式",
                dataId:"101",
                action1:"酸",
                action2:"碱"
            },
            {
                name:"氯泵加药模式",
                dataId:"102",
                action1:"ORP",
                action2:"余氯"
            }
        ]
    },

    sandTankList:[

    ],

    timingList:[

        {
            icon:require("@/assets/btn_disinfect.png"),
            name:"消毒运行时间",
            enable:{
                dataId:"99"
            },
            timeOnHour:{
                name:"定时开启-小时",
                dataId:"130"
            },
            timeOnMinute:{
                name:"定时开启-分钟",
                dataId:"131"
            },
            timeOffHour:{
                name:"定时关闭-小时",
                dataId:"132"
            },
            timeOffMinute:{
                name:"定时关闭-分钟",
                dataId:"133"
            },
        }
    ],

    systemControl:{
        standbyPump:{
            dataId:"134"
        },
        deviceTime:{
            yearDataId:"34",
            monthDataId:"35",
            dayDataId:"36",
            hourDataId:"37",
            minuteDataId:"38",
        },
        timeSync:{
            enableDataId:"78",
            yearDataId:"180",
            monthDataId:"181",
            dayDataId:"182",
            hourDataId:"183",
            minuteDataId:"184",
        },
        deviceRunTime:{
            dayDataId:"25",
            hourDataId:"26"
        },
        deviceLockTime:{
            yearDataId:"145",
            monthDataId:"146",
            dayDataId:"147"
        },
        deviceLockStatus:{
            dataId:"108"
        }
    },

    calibrationList:[
        {
            icon: "",
            name: "温度",
            color:"#00AAE4",
            dataIdArray:["150","151","152","153","154","155","12","10"],
            resetDataId:"103"
        },
        {
            icon: "",
            name: "余氯",
            color:"#94B420",
            dataIdArray:["156","157","158","159","160","161","15","13"],
            resetDataId:"104"
        },
        {
            icon: "",
            name: "PH",
            color:"#5A4D9D",
            dataIdArray:["168","169","170","171","172","173","21","19"],
            resetDataId:"106"
        },
        {
            icon: "",
            name: "ORP",
            color:"#4DBCCA",
            dataIdArray:["162","163","164","165","166","167","18","16"],
            resetDataId:"107"
        }
    ],

    urgentList:[

    ]
}
export default self
