import ics1 from "./ICS1"
import ics2 from "./ICS2"
import ics3 from "./ICS3"
import icsPro from "./ICS_PRO"
import icsMax from "./ICS_MAX"
let self = {
    getParas(model){
        if(model=="ICS_1"){
            return ics1
        }else if(model=="ICS_2"){
            return ics2
        }else if(model=="ICS_3"){
            return ics3
        }else if(model=="ICS_PRO"){
            return icsPro
        }else{
            return icsMax
        }
    }
}
export default self
