let self = {
    paraList:[
        {
            name:"水温",
            dataId:"10",
            unit:"℃",
            rate:10
        },
        {
            name:"酸碱",
            dataId:"19",
            unit:"PH",
            rate:100
        },
        {
            name:"ORP",
            dataId:"16",
            unit:"MV"
        },
        {
            name:"余氯",
            dataId:"13",
            unit:"PPM",
            rate:100
        },

    ],
    switchList:[
        {
            icon: require("@/assets/btn_pump.png"),
            selectedIcon: require("@/assets/btn_pump_selected.png"),
            name: "水泵一",
            readDataId:"41",
            writeDataId:"82"
        },
        {
            icon: require("@/assets/btn_pump.png"),
            selectedIcon: require("@/assets/btn_pump_selected.png"),
            name: "水泵二",
            readDataId:"42",
            writeDataId:"83"
        },
        {
            icon: require("@/assets/btn_pump.png"),
            selectedIcon: require("@/assets/btn_pump_selected.png"),
            name: "水泵三",
            readDataId:"43",
            writeDataId:"84"
        },
        {
            icon: require("@/assets/btn_pump.png"),
            selectedIcon: require("@/assets/btn_pump_selected.png"),
            name: "水泵四",
            readDataId:"44",
            writeDataId:"85"
        },
        {
            icon: require("@/assets/btn_pump.png"),
            selectedIcon: require("@/assets/btn_pump_selected.png"),
            name: "水泵五",
            readDataId:"45",
            writeDataId:"86"
        },
        {
            icon: require("@/assets/btn_light.png"),
            selectedIcon: require("@/assets/btn_light_selected.png"),
            name: "水下灯",
            readDataId:"46",
            writeDataId:"87"
        },
        {
            icon: require("@/assets/btn_disinfect.png"),
            selectedIcon: require("@/assets/btn_disinfect_selected.png"),
            name: "杀菌器",
            readDataId:"47",
            writeDataId:"88"
        },
        {
            icon: require("@/assets/btn_medical.png"),
            selectedIcon: require("@/assets/btn_medical_selected.png"),
            name: "PH泵",
            readDataId:"48",
            writeDataId:"89"
        },
        {
            icon: require("@/assets/btn_medical.png"),
            selectedIcon: require("@/assets/btn_medical_selected.png"),
            name: "氯泵",
            readDataId:"49",
            writeDataId:"90"
        },

    ],

    purification:{
        timing:[
            {
                icon:"",
                name:"酸碱设定",
                set:{
                    dataId:"110",
                    divider:0.1
                },
                start:{
                    name:"酸碱加药时间",
                    dataId:"111",
                    statusDataId:"62",
                },
                stop:{
                    name:"酸碱加药停止时间",
                    dataId:"112",
                    statusDataId:"63",
                }
            },
            {
                icon:"",
                name:"ORP设定",
                set:{
                    dataId:"113",
                    divider:1
                },
                start:{
                    name:"ORP加药时间",
                    dataId:"114",
                    statusDataId:"64",
                },
                stop:{
                    name:"ORP加药停止时间",
                    dataId:"115",
                    statusDataId:"65",
                }
            },

        ],
        control:[
            {
                icon: require("@/assets/btn_medical.png"),
                selectedIcon: require("@/assets/btn_medical_selected.png"),
                name: "PH泵",
                writeDataId:"89",
                readDataId:"48"
            },
            {
                icon: require("@/assets/btn_medical.png"),
                selectedIcon: require("@/assets/btn_medical_selected.png"),
                name: "氯泵",
                writeDataId:"90",
                readDataId:"49"
            },

        ],
        mode:[
            {
                name:"药泵控制模式",
                dataId:"100",
                action1:"手动",
                action2:"间断"
            },
            {
                name:"PH泵加药模式",
                dataId:"101",
                action1:"酸",
                action2:"碱"
            },

        ]
    },

    sandTankList:[
        {
            icon: "",
            name: "砂缸1",
            switchDataId:"92",
            chongxiDataId:"135",
            diguanDataId:"136",
            statusDataId:"28"
        },
        {
            icon: "",
            name: "砂缸2",
            switchDataId:"93",
            chongxiDataId:"137",
            diguanDataId:"138",
            statusDataId:"29"
        },
        {
            icon: "",
            name: "砂缸3",
            switchDataId:"94",
            chongxiDataId:"139",
            diguanDataId:"140",
            statusDataId:"30"
        },
        {
            icon: "",
            name: "砂缸4",
            switchDataId:"95",
            chongxiDataId:"141",
            diguanDataId:"142",
            statusDataId:"31"
        },
        {
            icon: "",
            name: "砂缸5",
            switchDataId:"96",
            chongxiDataId:"143",
            diguanDataId:"144",
            statusDataId:"32"
        }
    ],

    timingList:[
        {
            icon:require("@/assets/btn_pump.png"),
            name:"水泵运行时间",
            enable:{
                dataId:"97"
            },
            timeOnHour:{
                name:"定时开启-小时",
                dataId:"122"
            },
            timeOnMinute:{
                name:"定时开启-分钟",
                dataId:"123"
            },
            timeOffHour:{
                name:"定时关闭-小时",
                dataId:"124"
            },
            timeOffMinute:{
                name:"定时关闭-分钟",
                dataId:"125"
            },
        },
        {
            icon:require("@/assets/btn_light.png"),
            name:"水下灯运行时间",
            enable:{
                dataId:"98"
            },
            timeOnHour:{
                name:"定时开启-小时",
                dataId:"126"
            },
            timeOnMinute:{
                name:"定时开启-分钟",
                dataId:"127"
            },
            timeOffHour:{
                name:"定时关闭-小时",
                dataId:"128"
            },
            timeOffMinute:{
                name:"定时关闭-分钟",
                dataId:"129"
            },
        },
        {
            icon:require("@/assets/btn_disinfect.png"),
            name:"消毒运行时间",
            enable:{
                dataId:"99"
            },
            timeOnHour:{
                name:"定时开启-小时",
                dataId:"130"
            },
            timeOnMinute:{
                name:"定时开启-分钟",
                dataId:"131"
            },
            timeOffHour:{
                name:"定时关闭-小时",
                dataId:"132"
            },
            timeOffMinute:{
                name:"定时关闭-分钟",
                dataId:"133"
            },
        }
    ],

    systemControl:{
        standbyPump:{
            dataId:"134"
        },
        deviceTime:{
            yearDataId:"34",
            monthDataId:"35",
            dayDataId:"36",
            hourDataId:"37",
            minuteDataId:"38",
        },
        timeSync:{
            enableDataId:"78",
            yearDataId:"180",
            monthDataId:"181",
            dayDataId:"182",
            hourDataId:"183",
            minuteDataId:"184",
        },
        deviceRunTime:{
            dayDataId:"25",
            hourDataId:"26"
        },
        deviceLockTime:{
            yearDataId:"145",
            monthDataId:"146",
            dayDataId:"147"
        },
        deviceLockStatus:{
            dataId:"108"
        }
    },

    calibrationList:[
        {
            icon: "",
            name: "温度",
            color:"#00AAE4",
            dataIdArray:["150","151","152","153","154","155","12","10"],
            resetDataId:"103"
        },
        {
            icon: "",
            name: "PH",
            color:"#5A4D9D",
            dataIdArray:["168","169","170","171","172","173","21","19"],
            resetDataId:"106"
        },
        {
            icon: "",
            name: "ORP",
            color:"#4DBCCA",
            dataIdArray:["162","163","164","165","166","167","18","16"],
            resetDataId:"107"
        }
    ],

    urgentList:[
        {
            icon: require("@/assets/urgent-start.png"),
            selectedIcon: require("@/assets/urgent-start.png"),
            name: "一键启动",
            selectedName :"一键关闭",
            dataId:"73",
            writeDataId:"80"
        },
        {
            icon: require("@/assets/urgent-stop.png"),
            selectedIcon: require("@/assets/urgent-stop.png"),
            name: "紧急停止",
            selectedName :"紧急停止",
            dataId:"81",
            writeDataId: "81"
        },
    ]
}
export default self
