let self = {
    paraList:[

    ],
    switchList:[
        {
            icon: require("@/assets/btn_pump.png"),
            selectedIcon: require("@/assets/btn_pump_selected.png"),
            name: "水泵一",
            readDataId:"41",
            writeDataId:"82"
        },
        {
            icon: require("@/assets/btn_pump.png"),
            selectedIcon: require("@/assets/btn_pump_selected.png"),
            name: "水泵二",
            readDataId:"42",
            writeDataId:"83"
        },
        {
            icon: require("@/assets/btn_pump.png"),
            selectedIcon: require("@/assets/btn_pump_selected.png"),
            name: "水泵三",
            readDataId:"43",
            writeDataId:"84"
        },
        {
            icon: require("@/assets/btn_light.png"),
            selectedIcon: require("@/assets/btn_light_selected.png"),
            name: "水下灯",
            readDataId:"46",
            writeDataId:"87"
        },
        {
            icon: require("@/assets/btn_disinfect.png"),
            selectedIcon: require("@/assets/btn_disinfect_selected.png"),
            name: "杀菌器",
            readDataId:"47",
            writeDataId:"88"
        },
    ],

    purification:{
        timing:[

        ],
        control:[

        ],
        mode:[

        ]
    },

    sandTankList:[
        {
            icon: "",
            name: "砂缸1",
            switchDataId:"92",
            chongxiDataId:"135",
            diguanDataId:"136",
            statusDataId:"28"
        },
        {
            icon: "",
            name: "砂缸2",
            switchDataId:"93",
            chongxiDataId:"137",
            diguanDataId:"138",
            statusDataId:"29"
        },
    ],

    timingList:[
        {
            icon:require("@/assets/btn_pump.png"),
            name:"水泵运行时间",
            enable:{
                dataId:"97"
            },
            timeOnHour:{
                name:"定时开启-小时",
                dataId:"122"
            },
            timeOnMinute:{
                name:"定时开启-分钟",
                dataId:"123"
            },
            timeOffHour:{
                name:"定时关闭-小时",
                dataId:"124"
            },
            timeOffMinute:{
                name:"定时关闭-分钟",
                dataId:"125"
            },
        },
        {
            icon:require("@/assets/btn_light.png"),
            name:"水下灯运行时间",
            enable:{
                dataId:"98"
            },
            timeOnHour:{
                name:"定时开启-小时",
                dataId:"126"
            },
            timeOnMinute:{
                name:"定时开启-分钟",
                dataId:"127"
            },
            timeOffHour:{
                name:"定时关闭-小时",
                dataId:"128"
            },
            timeOffMinute:{
                name:"定时关闭-分钟",
                dataId:"129"
            },
        },
        {
            icon:require("@/assets/btn_disinfect.png"),
            name:"消毒运行时间",
            enable:{
                dataId:"99"
            },
            timeOnHour:{
                name:"定时开启-小时",
                dataId:"130"
            },
            timeOnMinute:{
                name:"定时开启-分钟",
                dataId:"131"
            },
            timeOffHour:{
                name:"定时关闭-小时",
                dataId:"132"
            },
            timeOffMinute:{
                name:"定时关闭-分钟",
                dataId:"133"
            },
        }
    ],

    systemControl:{
        standbyPump:{
            dataId:"134"
        },
        deviceTime:{
            yearDataId:"34",
            monthDataId:"35",
            dayDataId:"36",
            hourDataId:"37",
            minuteDataId:"38",
        },
        timeSync:{
            enableDataId:"78",
            yearDataId:"180",
            monthDataId:"181",
            dayDataId:"182",
            hourDataId:"183",
            minuteDataId:"184",
        },
        deviceRunTime:{
            dayDataId:"25",
            hourDataId:"26"
        },
        deviceLockTime:{
            yearDataId:"145",
            monthDataId:"146",
            dayDataId:"147"
        },
        deviceLockStatus:{
            dataId:"108"
        }
    },

    calibrationList:[

    ],

    urgentList:[
        {
            icon: require("@/assets/urgent-start.png"),
            selectedIcon: require("@/assets/urgent-start.png"),
            name: "一键启动",
            selectedName :"一键关闭",
            dataId:"73",
            writeDataId:"80"
        },
        {
            icon: require("@/assets/urgent-stop.png"),
            selectedIcon: require("@/assets/urgent-stop.png"),
            name: "紧急停止",
            selectedName :"紧急停止",
            dataId:"81",
            writeDataId: "81"
        },
    ]
}
export default self
